// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FlyingCarsSection from './components/FlyingCarsSection';
import PlatformSection from './components/PlatformSection';
import ProjectsSection from './components/ProjectsSection';
import Convergence from './components/Convergence';
import AboutUsSection from './components/AboutUsSection';
import FourItemsComponent from './components/FourItemsComponent';
import WorkComponent from './components/WorkComponent';
import Footer from './components/Footer';
import TermsPrivacyPage from './components/TermsPrivacyPage';
import CanonicalLink from './CanonicalLink'; // Import the CanonicalLink component

function App() {
  return (
  <BrowserRouter>
    <div className="App">
      <Header />
      <Routes>
       <Route
            path="/terms-privacy"
            element={<React.Fragment><TermsPrivacyPage /></React.Fragment>}
          />
        <Route path="/" element={<React.Fragment> {/* or <></> for short */}
          <HeroSection />
          <FlyingCarsSection />
          <PlatformSection />
          <ProjectsSection />
          <Convergence />
           <FourItemsComponent />
          <WorkComponent />
          <AboutUsSection />
           <Footer />
        </React.Fragment>} />
      </Routes>
     <CanonicalLink /> {/* Include CanonicalLink at the end of your App component */}
    </div>
  </BrowserRouter>
);
};

export default App;
