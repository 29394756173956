// CanonicalLink.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CanonicalLink = () => {
  const location = useLocation();
  const canonicalUrl = `https://icebergtribe.com${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} data-react-helmet="true" />
    </Helmet>
  );
};

export default CanonicalLink;
