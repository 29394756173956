import React, { useState, useEffect } from 'react';
import styles from './Convergence.module.css';
import Button from './Button'; // Import the Button component

const Convergence = () => {
    const [isConverged, setIsConverged] = useState(false);
    const [showOval, setShowOval] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const convergenceTriggerPosition = 1200;

            setIsConverged(scrollPosition >= convergenceTriggerPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = () => {
        setShowOval(!showOval);
    };


    return (
        <div className={`${styles['convergence-container']} ${isConverged ? styles['converged'] : ''}`}>
            <div className={styles.card}>
                <h3>How do we create new knowledge?</h3>
                
                <p>We create thoughtful and strategic integration of knowledge from disparate fields to create novel research projects.
                    Our emphasis is on promoting innovation through a well-considered convergence of diverse domains.</p>
                <Button label="Research Project >" onClick={handleButtonClick} />
            </div>
            <div className={`${styles['pie-chart']}`}>
                <svg viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
                    {Array.from({ length: 9 }).map((_, index) => {
                        const startAngle = (index * 360) / 9;
                        const endAngle = ((index + 1) * 360) / 9;
                        const startAngleRad = (startAngle - 90) * (Math.PI / 180);
                        const endAngleRad = (endAngle - 90) * (Math.PI / 180);

                        const x1 = 70 + 45 * Math.cos(startAngleRad);
                        const y1 = 70 + 45 * Math.sin(startAngleRad);
                        const x2 = 70 + 45 * Math.cos(endAngleRad);
                        const y2 = 70 + 45 * Math.sin(endAngleRad);

                        const pathData = `M70 70 L${x1} ${y1} A45 45 0 0 1 ${x2} ${y2} Z`;

                        return (
                            <g key={index}>
                                {/* Apply animation only to path elements */}
                                <path d={pathData} fill={`hsl(${index * (360 / 9)}, 100%, 50%)`} className={`${styles['animation']}`} />
                            </g>
                        );
                    })}
                    {Array.from({ length: 9 }).map((_, index) => {
                        const startAngle = (index * 360) / 9;
                        const endAngle = ((index + 1) * 360) / 9;

                        const labelRadius = 48;
                        const labelX = 70 + labelRadius * Math.cos((startAngle + endAngle) / 2 * (Math.PI / 180));
                        const labelY = 70 + labelRadius * Math.sin((startAngle + endAngle) / 2 * (Math.PI / 180));

                        const textAnchor = labelX > 70 ? "start" : "end";
                        const dy = labelY > 70 ? "1em" : "-0.5em";

                        // Define an array of labels
                        const labels = ['Mathematics', 'Engineering', 'Social Sciences', 'Humanities', 'Psychology', 'Medicine', 'Chemistry', 'Biology', 'Physics'];

                        return (

                            <text key={index} x={labelX} y={labelY} dy={dy} textAnchor={textAnchor} className={`${styles['pie-label']}`} >
                                {labels[index]}
                            </text>
                        );
                    })}
                    {showOval && (
                        <g>
                            <ellipse
                                className={styles.oval}
                                cx="50"
                                cy="80"
                                rx="18" // Adjust the horizontal radius as needed
                                ry="6"  // Adjust the vertical radius as needed
                            />
                            <text
                                x="50" // Adjust the x-coordinate of the text
                                y="80" // Adjust the y-coordinate of the text
                                textAnchor="middle" // Align the text in the middle horizontally
                                dominantBaseline="middle" // Align the text in the middle vertically
                                className={`${styles['pie-label']}`} // You can define a class for styling the label
                            >
                                Money Preferences
                            </text>
                        </g>
                    )}
                </svg>
            </div>
        </div>
    );



};

export default Convergence;
