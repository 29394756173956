import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './Platform.module.css'; // Import the CSS file

// SVG for the section divider
const SectionDivider = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={styles.sectionDivider}>
    <defs>
      <linearGradient id="shadingGradient" gradientTransform="rotate(90)">
        <stop offset="0%" style={{ stopColor: "blue", stopOpacity: 0 }} />
        <stop offset="50%" style={{ stopColor: "orange", stopOpacity: 0.5 }} />
        <stop offset="100%" style={{ stopColor: "blue", stopOpacity: 0 }} />
      </linearGradient>
    </defs>
    <path
      d="M0,192L60,170.7C120,149,240,107,360,85.3C480,64,600,64,720,80C840,96,960,128,1080,133.3C1200,139,1320,117,1380,106.7L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
    ></path>
  </svg>
);

const PlatformSection = () => {
  const [visible, setVisible] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const sections = [
    "ICEBERG TRIBE is a digital conglomerate that builds IP based products & brands for emerging markets.",
    "We stitch together opportunities, research themes & product visions to create a defensible innovation engine.",
    "Most modern innovations bring slight enhancements to an existing large market. Our focus is to build deeper innovations.",
    "ICEBERG TRIBE will incubate a portfolio of science-based products that will address emerging or create new markets. ",
    "Our vision is to build a culture that cherishes research & raises socio-economic standing of breakthroughs."
    // Add more sections as needed
  ];

  const fadeIn = useSpring({
    opacity: visible ? 1 : 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fadeInTriggerPosition = 500;

      setVisible(scrollPosition >= fadeInTriggerPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigation = (direction) => {
    if (direction === 'next') {
      // Set visible to false for fade-out effect
      setVisible(false);

      // After a brief delay, update the section and set visible to true for fade-in effect
      setTimeout(() => {
        setCurrentSection((prevSection) => (prevSection + 1) % sections.length);
        setVisible(true);
      }, 300); // Adjust the delay as needed
    }
  };

  return (
    <div>
      {/* SVG Section Divider */}
      <SectionDivider />

      {/* Your Platform Section */}
      <div id="platformSection" className={`${styles['platform-section']}`}>
        <animated.section style={fadeIn}>
          <div className={`${styles['platform-content']}`}>
            {sections[currentSection]}
          </div>
        </animated.section>

        {/* Navigation arrow button */}
        <button className={styles.arrowButton} onClick={() => handleNavigation('next')}>
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default PlatformSection;
