// HeroSection.jsx

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './Hero.module.css'; // Import the CSS file

const HeroSection = () => {
    const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

    const words = ['DREAMERS', 'VISIONARIES', 'TECHNOLOGISTS', 'RESEARCHERS', 'MAVERICKS', 'INVENTORS', 'INNOVATORS','PIONEERS', 'EXPLORERS'];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const updateWordIndex = () => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        };

        const intervalId = setInterval(updateWordIndex, 2000);

        return () => clearInterval(intervalId);
    }, [words.length]); // Include words.length in the dependency array

    return (
        <animated.section className={styles['hero-section']} style={fadeIn}>
            <div className={styles['hero-content']}>
                <h1 className={styles['custom-h1']}>
                    <div className={styles['calling-all']}>Calling all</div>
                    <div className={styles['changing-word']}>{words[currentWordIndex]}</div>
                </h1>
                <p className={styles['custom-p']}>
                    who prefer <span className={styles['emphasis']}>creation</span> more than disruption.
                </p>
                
            </div>
        </animated.section>
    );
};

export default HeroSection;
