// FourItemsComponent.jsx
import React, { useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './FourItemsComponent.module.css';

const FourItemsComponent = () => {
  const containerRef = useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  React.useEffect(() => {
    const handleScroll = () => {
      const containerTop = containerRef.current?.offsetTop - window.innerHeight/1.5;
      setIsVisible(window.scrollY > containerTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <animated.div className={styles.fourItemsContainer} style={fadeIn} ref={containerRef}>
      <h2 className={styles.sectionTitle}>Every product will be embedded in new technological paradigms</h2>

      <div className={styles.shapesContainer}>
        {/* Large Square */}
        <animated.div className={`${styles.shape} ${styles.largeSquare} ${styles.fadeIn}`}>
          <div className={styles.shapeContent}>
          <h2 className={styles.customH2}>AI</h2>
    <p className={styles.customP}>We create businesses leveraging AI for specialized markets. 
    Additionally, we contribute to research by building novel, use-inspired AI modules.</p>
          </div>
        </animated.div>

        {/* Stack of Three Rectangles */}
        <div className={styles.stackContainer}>
          <animated.div className={`${styles.shape} ${styles.rectangle} ${styles.fadeIn}`}>
            <div className={styles.shapeContent}>
            <h2 className={styles.customH2}>IOT</h2>
            </div>
          </animated.div>

          <animated.div className={`${styles.shape} ${styles.rectangle} ${styles.fadeIn}`}>
            <div className={styles.shapeContent}>
            <h2 className={styles.customH2}>AR</h2>
            </div>
          </animated.div>

          <animated.div className={`${styles.shape} ${styles.rectangle} ${styles.fadeIn}`}>
            <div className={styles.shapeContent}>
            <h2 className={styles.customH2}>Robotics</h2>
            </div>
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
};

export default FourItemsComponent;
