// TermsPrivacyPage.js
import React from 'react';
import styles from './TermsPrivacyPage.module.css';

const TermsPrivacyPage = () => {
  return (
    <div className={styles.termsPrivacyPage}>
      <h1 className={styles.privacyh2}>Terms of Service</h1>

      <h2 className={styles.privacyh2}>Introduction</h2>
      <p className={styles.privacyp}>
        Welcome to icebergtribe.com ("we," "us," or "our"). By using our website, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use our website.
      </p>

      <h2 className={styles.privacyh2}>Use of Our Website</h2>
      <p className={styles.privacyp}>
        a. You must be at least 18 years old to use our website.
      </p>
      <p className={styles.privacyp}>
        b. You are responsible for maintaining the confidentiality of your account and password.
      </p>
      <p className={styles.privacyp}>
        c. You agree to use our website in accordance with all applicable laws and regulations.
      </p>

      <h2 className={styles.privacyh2}>User Content</h2>
      <p className={styles.privacyp}>
        a. By submitting content on our website, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform your content.
      </p>
      <p className={styles.privacyp}>
        b. You are solely responsible for the content you submit, and you represent and warrant that you have all necessary rights to do so.
      </p>

      <h2 className={styles.privacyh2}>Intellectual Property</h2>
      <p className={styles.privacyp}>
        a. All content on our website, including text, graphics, logos, and images, is the property of Iceberg Tribe LLP, India and is protected by intellectual property laws.
      </p>
      <p className={styles.privacyp}>
        b. You may not reproduce, distribute, modify, or otherwise use our content without our express written consent.
      </p>

      <h2 className={styles.privacyh2}>Limitation of Liability</h2>
      <p className={styles.privacyp}>
        a. We are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website.
      </p>
      <p className={styles.privacyp}>
        b. We do not warrant that our website will be error-free or uninterrupted.
      </p>

      <h2 className={styles.privacyh2}>Termination</h2>
      <p className={styles.privacyp}>
        We reserve the right to terminate or suspend your account and access to our website for any reason, without notice.
      </p>

      <h2 className={styles.privacyh2}>Changes to Terms of Service</h2>
      <p className={styles.privacyp}>
        We may revise these terms at any time without notice. By using our website, you agree to be bound by the current version of these terms.
      </p>

      <h1 className={styles.privacyh2}>Privacy Policy</h1>

      <h2 className={styles.privacyh2}>Information We Collect</h2>
      <p className={styles.privacyp}>
        a. We collect personal information that you provide to us, including but not limited to, your name, email address, and other contact information.
      </p>
      <p className={styles.privacyp}>
        b. We also collect non-personal information such as IP addresses, browser type, and usage patterns.
      </p>

      <h2 className={styles.privacyh2}>How We Use Your Information</h2>
      <p className={styles.privacyp}>
        a. We use your personal information to provide and improve our services.
      </p>
      <p className={styles.privacyp}>
        b. We may share your information with third parties for certain purposes, such as payment processing and analytics.
      </p>

      <h2 className={styles.privacyh2}>Cookies and Tracking Technologies</h2>
      <p className={styles.privacyp}>
        a. We use cookies and similar technologies to track user activity and collect information about your use of our website.
      </p>
      <p className={styles.privacyp}>
        b. You can control cookies through your browser settings.
      </p>

      <h2 className={styles.privacyh2}>Security</h2>
      <p className={styles.privacyp}>
        We take reasonable measures to protect your personal information, but we cannot guarantee its absolute security.
      </p>

      <h2 className={styles.privacyh2}>Third-Party Links</h2>
      <p className={styles.privacyp}>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.
      </p>

      <h2 className={styles.privacyh2}>Changes to Privacy Policy</h2>
      <p className={styles.privacyp}>
        We may update our Privacy Policy from time to time. Please review it periodically.
      </p>

      <h2 className={styles.privacyh2}>Contact Information</h2>
      <p className={styles.privacyp}>
        If you have any questions about our Terms of Service or Privacy Policy, you can contact us at dhruv@icebergtribe.com.
      </p>
    </div>
  );
};

export default TermsPrivacyPage;
