// FlyingCarsSection.js

import React, { useState } from 'react';
import styles from './Flying.module.css';
import { useSpring, animated } from 'react-spring';

const FlyingCarsSection = () => {
    const fadeIn = useSpring({
        opacity: 1,
        from: { opacity: 0 },
    });

    const contentArray = [
        // Add your content elements here
        <p key={1}>
            The period from 1880 to 1970s brought numerous technological advances into our lives. The long list of new developments includes
            electricity, electric lights, powerful motors, automobiles, airplanes, household appliances, the telephone, pharmaceuticals, mass production,
            the typewriter, the tape recorder, the phonograph, and radio, to name just a few, with television coming at the end of that period.
        </p>,
        <p key={2}>
            Today, in contrast, apart from the seemingly magical internet and mobile, life in broad material terms isn’t so different from 1970s. We still drive cars,
            use refrigerators, and turn on the light switch. The wonders portrayed in The Jetsons,
            the cartoon from the 1960s, have not come to pass. You don’t have a jet pack. You won’t live forever or visit a Mars colony.
            Life is better and we have more stuff, but the pace of change has slowed down.
        </p>,
        
        // Add more content elements as needed
    ];

    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const handleRightArrowClick = () => {
        if (currentContentIndex < contentArray.length - 1) {
            setCurrentContentIndex(currentContentIndex + 1);
        }
    };

    const handleLeftArrowClick = () => {
        if (currentContentIndex > 0) {
            setCurrentContentIndex(currentContentIndex - 1);
        }
    };
    
    return (
        <animated.section className={styles['flying-cars-section']} style={fadeIn}>
            <div className={styles.card}>
                <h3>"We wanted flying cars, but we got 140 characters!" - Peter Thiel</h3>
                <div className={styles.content}>{contentArray[currentContentIndex]}</div>
                <div className={styles['book-reference']} >
                    <h6 className={styles.customH6}>Book Reference: The Great Stagnation</h6>
                    
                </div>
                <div className={styles['arrow-row']}>
                    <div
                        className={`${styles['scroll-arrow']} ${styles.left} ${
                          currentContentIndex === 0 ? styles.disabled : ''
                        }`}
                        onClick={handleLeftArrowClick}
                      >
                        &lt;
                      </div>
                      <div
                        className={`${styles['scroll-arrow']} ${styles.right} ${
                          currentContentIndex === contentArray.length - 1 ? styles.disabled : ''
                        }`}
                        onClick={handleRightArrowClick}
                      >
                        &gt;
                      </div>
                </div>
            </div>
        </animated.section>
    );
};

export default FlyingCarsSection;