// WorkComponent.jsx
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './WorkComponent.module.css';
import Button from './Button'; // Import the Button component

const WorkComponent = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleButtonClick = () => {
        // Handle button click logic
        window.open("https://forms.gle/k1dSZP5Q4CkNHk6j6", "_blank");
    };

  const fadeIn = useSpring({
    opacity: isScrolled ? 1 : 0,
  });

  const data = [
    {
      id: 1,
      category: 'Research',
      title: 'Project 1',
      content: ['Money Preferences', ''],
      details: 'Indo-French research project to develop an AI model to estimate money senstivities.',
      link: '',
    },
    {
      id: 2,
      category: 'MVP',
      title: 'Haarmoney',
      content: ['Financial Compatibility', ''],
      details: 'A platform to build financial harmony between couples.',
      link: 'haarmoney.com',
    },
    {
      id: 3,
      category: 'MVP',
      title: 'Wishh',
      content: ['Financial Education', ''],
      details: 'A platform to optimise financial decisions.',
      link: 'wishh.in',
    },
    // Add more projects as needed
  ];

  const handleArrowClick = (id) => {
    setSelectedItem(id);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fadeInTriggerPosition = 2300;

      setIsScrolled(scrollPosition >= fadeInTriggerPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <animated.div style={fadeIn}  className={styles.ItemsContainer}>
      <h2 className={styles.sectionTitle}>Ongoing Work</h2>
      <div className={styles.workContainer}>
        <div className={styles.column}>
          <ul className={styles.list}>
            {data.map((item) => (
              <li key={item.id} className={styles.listItem}>
                <div className={styles.category}>{item.category}</div>
                <div className={styles.projectTitle}>{item.title}</div>
                {item.id === selectedItem && (
                  <ul className={styles.subList}>
                    {item.content.map((subItem, index) => (
                      <li key={index} className={styles.subListItem}>
                        {subItem}
                      </li>
                    ))}
                  </ul>
                )}
                <button className={styles.arrowButton} onClick={() => handleArrowClick(item.id)}>
                  {'>>'}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.column}>
          {selectedItem !== null && (
            <div className={styles.selectedContent}>
              <h2 className={styles.sectionH2}>{data[selectedItem - 1].title}</h2>
              <p  className={styles.sectionP}>{data[selectedItem - 1].details}</p>
              {/* Conditionally render the button if there is no link */}
                  {data[selectedItem - 1].link ? null : (
                    <div className={styles.connectButton}>
                      <Button label="Learn More >>" onClick={handleButtonClick} />
                    </div>
                  )}
              {/* Render the link only if there is a link */}
                {data[selectedItem - 1].link && (
                  <a
                    href={`https://${data[selectedItem - 1].link}`}
                    target="_blank" // Use "_self" if you want to open in the same tab
                    rel="noopener noreferrer"
                  >
                    {data[selectedItem - 1].link}
                  </a>
                )}
            </div>
          )}
        </div>
      
    </div>
    </animated.div>
  );
};

export default WorkComponent;
