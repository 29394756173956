import React, { useRef, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './Projects.module.css';

const ProjectsSection = () => {
    const scrollRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const sectionOffset = 900;

            setIsVisible(scrollPosition >= sectionOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fadeInAnimation = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    });
    
    return (
        <animated.section ref={scrollRef} style={fadeInAnimation} className={styles['projects-section']}>
           <h2 className={styles.customH2}>Our kind of Innovation!</h2>
            <div className={styles['projects-content']}>
                <div className={`${styles['project-axis']} ${styles['vertical-axis']} ${styles['top-axis']}`}>New Knowledge</div>
                <div className={styles['project-card']}>
                    <h3>Fundamental Research.</h3>
                    <p>Eg- Finding new particles </p>
                </div>

                <div className={styles['project-card']}>
                    <h3 style={{ color: '#00CCFF' }}>ICEBERG TRIBE</h3>
                    <p>IP based products</p>
                </div>

                <div className={`${styles['project-axis']} ${styles['vertical-axis']} ${styles['bottom-axis']}`}>Existing Knowledge</div>
                <div className={`${styles['project-axis']} ${styles['horizontal-axis']} ${styles['left-axis']}`}>No Application</div>
                <div className={styles['project-card']}>
                    <h3>Detailing Discovery. </h3>
                    <p>Eg- Black holes</p>
                </div>

                <div className={styles['project-card']}>
                    <h3>Current Tech.</h3>
                    <p>Disrupt with new usage</p>
                </div>

                <div className={`${styles['project-axis']} ${styles['horizontal-axis']} ${styles['right-axis']}`}>New Application</div>
            </div>
        </animated.section>
    );
};

export default ProjectsSection;
