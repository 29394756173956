// components/AboutUsSection.js
import React from 'react';
import styles from './About.module.css'; // Import the CSS file

const SectionDivider = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={styles.sectionDivider}>
    <defs>
      <linearGradient id="shadingGradient" gradientTransform="rotate(90)">
        <stop offset="0%" style={{ stopColor: "blue", stopOpacity: 0 }} />
        <stop offset="50%" style={{ stopColor: "white", stopOpacity: 0.5 }} />
        <stop offset="100%" style={{ stopColor: "blue", stopOpacity: 0 }} />
      </linearGradient>
    </defs>
    <path
      d="M0,96L48,80C96,64,192,32,288,48C384,64,480,128,576,144C672,160,768,128,864,117.3C960,107,1056,117,1152,133.3C1248,149,1344,171,1392,181.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
    ></path>
  </svg>
);

const AboutUsSection = () => {

    const teamMembers = [
    {
      id: 1,
      name: 'Dhruv Arora',
      title: 'Partner',
      linkedin: 'https://www.linkedin.com/in/dhruvaroratnl/',
      image: '/dhruv.jpg', // Replace with actual image path
    },
      {
      id: 2,
      name: 'Ganesh Gowrishankar',
      title: 'Senior Researcher',
      linkedin: 'https://www.lirmm.fr/ganesh-gowrishankar/',
      image: '/ganesh.jpg', // Replace with actual image path
    },
      {
      id: 3,
      name: 'Vikas Pandey',
      title: 'Partner',
      linkedin: 'https://www.linkedin.com/in/v-pandey',
      image: '/vikas.jpg', // Replace with actual image path
    },
      {
      id: 4,
      name: 'Sreekar Vadlamani',
      title: 'Associate Professor',
      linkedin: 'https://sreekar.tifrbng.res.in/',
      image: '/sreekar2.jpg', // Replace with actual image path
    },
    {
      id: 5,
      name: 'Apurva Parekh',
      title: 'Advisor',
      linkedin: 'https://www.linkedin.com/in/apurvaparekh',
      image: '/apurva.jpg', // Replace with actual image path
    },
    // Add more team members as needed
  ];

  // Shuffle array to organize images in order
  const sortedTeamMembers = teamMembers.sort((a, b) => a.id - b.id);


  return (

    <section className={styles['about-us-section']}>
    <div>
      {/* SVG Section Divider */}
      <SectionDivider />
      <div className={styles['about-us-content']}>
        <h2 className={styles['custom-h2']}>About Us</h2>
        <p className={styles['custom-p']}>It's a growing community</p>
      </div>
      </div>
      <div className={styles.teamContainer}>
        {sortedTeamMembers.map((member) => (
          <div key={member.id} className={styles.teamMember}>
            <img src={member.image} alt={member.name} className={styles.memberImage} />
            <div className={styles.memberInfo}>
              <h2 className={styles.customh2}>{member.name}</h2>
              <p className={styles.customp}>{member.title}</p>
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                About >>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutUsSection;

